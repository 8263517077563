<template>
  <div style="margin-top: 30px; width: 90%; max-width: 1300px">
    <CRow>
      <CCol col="12" md="6">
        <div v-if="role == 9">
          <div style="display: flex; justify-content: flex-end; width: 400px">
            <button class="saveBtn" @click="addNew">
              Añadir
              <font-awesome-icon
                icon="plus"
                style="font-size: 15px; margin-left: 6px; margin-bottom: 3px"
              />
            </button>
          </div>

          <CDataTable
            :fields="fields"
            :items="candidatos"
            hover
            sorter
            style="margin-top: 24px; width: 400px"
            :items-per-page="limit"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  class="mr-2"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentItem(item, 'edit')"
                >
                  <img style="width: 1rem" src="../../assets/icons/svg/edit.svg" alt="" />
                </CButton>
                <!--               <CButton
                color="red"
                variant="outline"
                square
                size="sm"
                @click="setCurrentItem(item, 'delete')"
              >
                <img
                  style="width: 1rem"
                  src="../../assets/icons/svg/delete.svg"
                  alt=""
                />
              </CButton> -->
              </td>
            </template>
          </CDataTable>
          <div style="display: flex; justify-content: center !important">
            <CPagination :activePage.sync="start" :pages="total" />
          </div>
        </div>
      </CCol>

      <CCol col="12" md="6">
        <div>
          <h5 v-if="unique_id">Código de Afiliación: {{ unique_id }}</h5>
          <CRow
            style="max-width: 900px; padding-top: 40px; margin-scroll-top: 20px"
            ref="form"
          >
            <CCol col="12" md="3">
              <label for="file-input" style="position: relative">
                <font-awesome-icon
                  icon="camera"
                  style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    cursor: pointer;
                    font-size: 24px;
                  "
                />
                <img
                  class="UserConfig__Header__Data__Img"
                  style="
                    margin: 0 auto;
                    border-radius: 50%;
                    object-fit: cover;
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    background: #f7f5f0;
                  "
                  :src="avatarImg ? avatarImg : require('../../assets/user.svg')"
                />
              </label>
              <input
                id="file-input"
                style="display: none"
                @change="onChangeProfileImg"
                type="file"
              />
            </CCol>
            <CCol col="12" md="9">
              <CInput label="Nombre" v-model="candidato.name" />
            </CCol>
          </CRow>
          <CRow style="max-width: 900px; margin-top: 12px; padding-bottom: 12px">
            <CCol></CCol>
          </CRow>
          <CRow
            style="
              max-width: 900px;
              padding-bottom: 12px;
              border-bottom: 1px solid #e4e4e4;
            "
          >
            <CCol md="6" sm="12">
              <CTextarea
                maxlength="250"
                label="Descripción Corta"
                v-model="candidato.short_description"
              />
            </CCol>
            <CCol md="6" sm="12">
              <CTextarea label="Descripción " v-model="candidato.description" />
            </CCol>
            <CCol sm="12">
              <CTextarea
                label="Descripción hablada"
                v-model="candidato.speak_description"
              />
            </CCol>
          </CRow>

          <h5 style="display: block">Colores</h5>
          <CRow
            style="
              max-width: 900px;
              padding-bottom: 8px;
              border-bottom: 1px solid #e4e4e4;
            "
          >
            <CCol
              style="display: flex; flex-direction: column; width: 50%"
              sm="6"
              md="4"
              class="color-input"
            >
              <label style="margin-right: 10px">Color Primario: </label>
              <input type="color" v-model="candidato.primary_color" />
            </CCol>
            <CCol
              style="display: flex; flex-direction: column; width: 50%"
              sm="6"
              md="4"
              class="color-input"
            >
              <label style="margin-right: 10px">Color Secundario: </label>
              <input type="color" v-model="candidato.secondary_color" />
            </CCol>
          </CRow>

          <h5 style="display: block; margin-top: 12px">Redes</h5>
          <CRow
            style="
              max-width: 900px;
              padding-bottom: 8px;
              border-bottom: 1px solid #e4e4e4;
            "
          >
            <CCol md="6" sm="12">
              <CInput
                placeholder="https://www.example.cl"
                label="Web"
                v-model="candidato.web_url"
              />
            </CCol>
            <CCol md="6" sm="12">
              <CInput
                placeholder="https://www.instagram.com/example"
                label="Instagram"
                v-model="candidato.instagram"
              />
            </CCol>
            <CCol md="6" sm="12">
              <CInput
                placeholder="https://www.twitter.com/example"
                label="Twitter"
                v-model="candidato.twitter"
              />
            </CCol>
            <CCol md="6" sm="12">
              <CInput
                placeholder="https://www.facebook.com/example"
                label="Facebook"
                v-model="candidato.facebook"
              />
            </CCol>
          </CRow>

          <div style="display: flex; align-items: center">
            <h5 style="display: block; margin-top: 12px">Afiliación</h5>
            <CInputCheckbox
              alignVertical="center"
              alignHorizontal="center"
              variant="opposite"
              color="dark"
              :checked.sync="hasAffiliation"
              class="ml-3 checkbox"
              style="display: flex; align-items: center"
            />
          </div>
          <CRow
            v-show="hasAffiliation"
            style="
              max-width: 900px;
              padding-bottom: 8px;
              border-bottom: 1px solid #e4e4e4;
            "
          >
            <CCol md="6" sm="12">
              <CInput label="Nombre" v-model="candidato.affiliation.name" />
            </CCol>
            <CCol md="6" sm="12">
              <CInput label="Web" v-model="candidato.affiliation.url" />
            </CCol>
            <CCol md="6" sm="12">
              <CTextarea
                style="height: 150px"
                label="Descripción"
                v-model="candidato.affiliation.description"
              />
            </CCol>
            <CCol md="6" sm="12">
              <label class="label-img">Imagen: </label>
              <div class="input-group file-input-group" data-controller="file-input2">
                <input
                  :value="fileNameImageAffiliation"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  placeholder="Selecionar archivo"
                  readonly
                  data-target="file-input2.value"
                />
                <input
                  @change="onChangeImageAffiliation"
                  type="file"
                  class="form-control"
                  id="customFile2"
                  data-target="file-input2.input"
                  data-action="file-input2#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile2"
                    ><img
                      style="width: 18px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <img
                v-if="previewImageAffiliation"
                class="img-preview"
                :src="previewImageAffiliation"
                alt="preview"
              />
            </CCol>
          </CRow>

          <small style="display: block" v-if="disableBtn"
            >* Hay campos sin completar</small
          >
          <CRow style="max-width: 900px; margin-top: 12px; padding-bottom: 12px">
            <CCol sm="12" md="6" style="display: flex">
              <button
                :disabled="disableBtn"
                class="saveBtn"
                @click="
                  createOrEdit == 'create' ? createCandidate() : updateCandidateConfig()
                "
              >
                {{ createOrEdit == "create" ? "Crear" : "Guardar Cambios" }}
              </button>
              <div style="max-width: 900px">
                <div class="alert" style="padding: 0">
                  <CAlert v-if="alertSuccess" color="success"
                    >Configuración actualizada</CAlert
                  >
                </div>
                <div class="alert">
                  <CAlert v-if="alertError" color="red">Error al acutalizar</CAlert>
                </div>
              </div>
            </CCol>
            <CCol></CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>

    <!--     Modal Eliminar -->
    <!--         <CModal
          title="Eliminar Patrocinador"
          color="warning"
          :show.sync="modalDelete"
          size="sm"
        >
          ¿Seguro que quieres eliminar la noticia
          <span style="font-weight: 800">{{ candidato.name }}</span
          >?
          <div slot="footer" class="w-100">
            <CButton
              class="mr-2"
              color="red"
              variant="outline"
              square
              @click="deleteSponsor()"
            >
              Eliminar
            </CButton>
            <CButton
              color="secondary"
              variant="outline"
              square
              @click="() => (modalDelete = !modalDelete)"
            >
              Cancelar
            </CButton>
          </div>
        </CModal> -->
  </div>
</template>

<script>
import {
  getSponsorInfo,
  updateSponsorInfo,
  createSponsor,
  getAdminSponsors,
  removeAffiliation,
} from "../../api/sponsor";
export default {
  data() {
    return {
      role: null,
      unique_id: null,
      candidatos: [],
      fields: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      start: 1,
      limit: 10,
      total: 0,

      candidato: {
        name: "",
        main_image: null,
        short_description: "",
        speak_description: "",
        description: "",
        primary_color: "",
        secondary_color: "",
        sponsor_id: "",
        web_url: "",
        instagram: "",
        twitter: "",
        facebook: "",
        affiliation: {
          name: "",
          description: "",
          url: "",
          image: "",
        },
      },
      avatarImg: null,
      alertSuccess: false,
      alertError: false,
      modalDelete: false,
      createOrEdit: "create",

      fileNameImageAffiliation: "",
      previewImageAffiliation: null,

      hasAffiliation: true,
    };
  },
  computed: {
    disableBtn() {
      if (
        !this.candidato.name ||
        !this.candidato.main_image ||
        !this.candidato.short_description ||
        !this.candidato.speak_description ||
        !this.candidato.description ||
        !this.candidato.primary_color ||
        !this.candidato.secondary_color ||
        !this.candidato.web_url ||
        (this.hasAffiliation &&
          (!this.candidato.affiliation.name ||
            !this.candidato.affiliation.description ||
            !this.candidato.affiliation.image ||
            !this.candidato.affiliation.url))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onChangeProfileImg(e) {
      this.editedImg = true;
      this.candidato.main_image = e.target.files["0"];
      this.avatarImg = URL.createObjectURL(e.target.files["0"]);
    },
    onChangeImageAffiliation(e) {
      this.candidato.affiliation.image = e.target.files["0"];
      this.previewImageAffiliation = URL.createObjectURL(e.target.files["0"]);
      this.fileNameImageAffiliation = e.target.files["0"].name;
    },
    addNew() {
      this.createOrEdit = "create";
      this.candidato = {
        name: "",
        main_image: null,
        short_description: "",
        speak_description: "",
        description: "",
        primary_color: "",
        secondary_color: "",
        sponsor_id: "",
        web_url: "",
        instagram: "",
        twitter: "",
        facebook: "",
        affiliation: {
          name: "",
          description: "",
          url: "",
          image: "",
        },
      };
      this.unique_id = null;
      this.editedImg = null;
      this.candidato.main_image = null;
      this.avatarImg = null;

      this.fileNameImageAffiliation = "";
      this.previewImageAffiliation = null;

      this.$refs.form.scrollIntoView({ behavior: "smooth" });
    },
    createCandidate() {
      const data = new FormData();
      const obj = { ...this.candidato };
      delete obj.sponsor_id;

      !this.hasAffiliation && delete obj.affiliation;

      for (const property in obj) {
        if (property == "affiliation") {
          data.append("affiliation[name]", obj[property].name);
          data.append("affiliation[description]", obj[property].description);
          data.append("affiliation[image]", obj[property].image);
          data.append("affiliation[url]", obj[property].url);
        } else {
          data.append(property, obj[property]);
        }
      }

      createSponsor(data).then((res) => {
        this.getSponsorsList();
        this.candidato = {
          name: "",
          main_image: null,
          short_description: "",
          speak_description: "",
          description: "",
          primary_color: "",
          secondary_color: "",
          sponsor_id: "",
          web_url: "",
          instagram: "",
          twitter: "",
          facebook: "",
          affiliation: {
            name: "",
            description: "",
            url: "",
            image: "",
          },
        };
        this.unique_id = null;
        this.editedImg = null;
        this.candidato.main_image = null;
        this.avatarImg = null;

        this.fileNameImageAffiliation = "";
        this.previewImageAffiliation = null;
      });
    },
    setCurrentItem(item, editOrDelete) {
      if (editOrDelete == "edit") {
        this.createOrEdit = "edit";
        this.candidato = { ...item };
        this.candidato.sponsor_id = item.id;
        this.unique_id = item.unique_id;
        this.avatarImg = item.main_image_link;
        this.previewImageAffiliation = item?.affiliation?.image_link;
        /*         this.$refs.form.scrollIntoView({ behavior: "smooth" }); */

        this.hasAffiliation = item.affiliation ? true : false;

        if (!item.affiliation) {
          this.candidato.affiliation = {
            name: "",
            description: "",
            url: "",
            image: "",
          };
        }
      } else if (editOrDelete == "delete") {
        this.modalDelete = true;
      }
    },
    getSponsorsList() {
      getAdminSponsors().then((res) => {
        this.candidatos = res.sponsors.map((sponsor) => { 
          sponsor.web_url = sponsor.socials?.web_url;
          sponsor.instagram = sponsor.socials?.instagram;
          sponsor.twitter = sponsor.socials?.twitter;
          sponsor.facebook = sponsor.socials?.facebook;
          return sponsor;
        });
      });
    },

    updateCandidateConfig() {
      const data = new FormData();

      const obj = { ...this.candidato };
      !this.hasAffiliation && delete obj.affiliation;
      typeof this.candidato.main_image == "string" && delete obj.main_image;

      for (const property in obj) {
        if (property == "affiliation") {
          if (typeof obj[property].image !== "string") {
            data.append("affiliation[image]", obj[property].image);
          }
          data.append("affiliation[name]", obj[property].name);
          data.append("affiliation[description]", obj[property].description);
          data.append("affiliation[url]", obj[property].url);
        } else {
          data.append(property, obj[property]);
        }
      }

      updateSponsorInfo(data)
        .then((res) => {
          this.alertSuccess = true;
          setTimeout(() => {
            this.alertSuccess = false;
          }, 1000);
        })
        .catch((e) => {
          this.alertError = true;
          setTimeout(() => {
            this.alertError = false;
          }, 1000);
        });
    },
    getCandidateInfo() {
      getSponsorInfo().then((res) => {
        const { sponsor } = res;
        this.candidato = sponsor;

        this.candidato.main_image = sponsor.main_image_link;
        this.candidato.web_url = sponsor.socials?.web_url;
        this.candidato.instagram = sponsor.socials?.instagram;
        this.candidato.twitter = sponsor.socials?.twitter;
        this.candidato.facebook = sponsor.socials?.facebook;

        this.avatarImg = sponsor.main_image_link;
        this.previewImageAffiliation = sponsor.affiliation?.image_link;
        this.unique_id = sponsor.unique_id;

        this.hasAffiliation = sponsor.affiliation ? true : false;
      });
    },
    deleteAffiliation() {
      const data = { sponsor_id: this.candidato.id };
      removeAffiliation(data).then((res) => {
        this.candidato.affiliation = {
          name: "",
          description: "",
          url: "",
          image: "",
        };
        this.previewImageAffiliation = null;
      });
    },
  },
  mounted() {
    this.role = this.$store.state.userData?.type;

    if (this.role == 9) {
      this.getSponsorsList();
      this.createOrEdit == "create";
    } else {
      this.getCandidateInfo();
      this.createOrEdit = "edit";
    }
  },
  watch: {
    hasAffiliation() {
      if (!this.hasAffiliation) {
        this.deleteAffiliation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";

.saveBtn {
  background: transparent
    linear-gradient(180deg, #D0282E 0%, #e66065 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 7px;
  opacity: 1;
  top: 410px;
  left: 339px;
  width: 123px;
  height: 37px;
  font: normal normal normal 14px/18px Montserrat;
  color: #fff;
  border: none;
}
.saveBtn:disabled,
.saveBtn[disabled] {
  opacity: 0.7;
}
.alert {
  display: inline-block;
  margin-left: 50px;
}
html {
  scroll-behavior: smooth;
}

.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
.img-preview {
  margin-top: 8px;
  width: 150px;
  height: 150px;
  object-fit: contain;
}
</style>

import { endpoints } from './endpoints'
import request from '@/helpers/request'

//Noticias
//////////////////////////////////////////

export function getSponsorNews(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSponsorNews,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function deleteNews(id) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.deleteNews}?news_id=${id}`,
			method: 'delete'
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function createNews(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createNews,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function updateNews(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateNews,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function getLocalNewsConfigOptions(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getLocalNewsConfigOptions,
			method: 'get',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}
	
	
	export function changeNewsConfigOptions(data) {
		return new Promise((resolve, reject) => {
			request({
				url: endpoints.changeNewsConfigOptions,
				method: 'post',
				data
			})
				.then(async function (response) {
					resolve(response)
				}).catch(function (error) {
					reject(error)
				})
			})
		}
		export function changeNewsConfigExternalOptions(data) {
			return new Promise((resolve, reject) => {
				request({
					url: endpoints.changeNewsConfigExternalOptions,
					method: 'patch',
					data
			})
				.then(async function (response) {
					resolve(response)
				}).catch(function (error) {
					reject(error)
				})
			})
		}
import {endpoints} from './endpoints'
import request from '../helpers/request'
//CANDIDATO
/////////////////////////

export function getSponsorInfo() {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.getSponsorInfo}`,
			method: 'get',
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function updateSponsorInfo(data) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.updateSponsorInfo}`,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function createSponsor(data) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.createSponsor}`,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function getAdminSponsors(data) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.getAdminSponsors}`,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function removeAffiliation(data) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.removeAffiliation}`,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}

export function setUserSponsor(data) {
	return new Promise((resolve, reject) => {
		request({
			url: `${endpoints.setUserSponsor}`,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
	})
}
